import {
  DownloadOutlined,
  SearchOutlined
} from '@ant-design/icons'
import R from 'app/assets/R'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { BORDER_RADIUS, PADDING } from 'parkway-web-common'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useHook } from './hook'
import { ImportFileModal, ModalAddTreatmentLayout } from './layouts'

export const TreatmentTypeLayout = () => {
  const { t } = useTranslation()
  const {
    columns,
    data,
    search,
    onChangeSearch,
    listService,
    onChangeService,
    handleGetImportTemplate,
    loading,
  } = useHook()

  return (
    <RootWrapper size={4}>
      <HeaderWrapper size={8}>
        <BaseRow justify={'space-between'} gutter={[16, 8]} align={'middle'}>
          <BaseCol>
            <BaseText
              children={t(R.strings.apply_service)}
              fontWeight="semibold"
              fontSize="xs"
            />
          </BaseCol>
          <BaseCol>
            <BaseRow gutter={[16, 8]} align={'middle'}>
              <BaseCol>
                <BaseButton
                  icon={<DownloadOutlined rev={undefined} />}
                  onClick={handleGetImportTemplate}
                  loading={loading}
                >
                  {t(R.strings.download_example_import_file)}
                </BaseButton>
              </BaseCol>
              <BaseCol>
                <ImportFileModal />
              </BaseCol>
              <BaseCol>
                <ModalAddTreatmentLayout
                  onChangeService={onChangeService}
                  services={listService}
                />
              </BaseCol>
            </BaseRow>
          </BaseCol>
        </BaseRow>

        <BaseRow>
          <BaseCol xl={8}>
            <BaseFormItem hiddenLabel>
              <BaseInput
                placeholder={t(R.strings.enter_service_name)}
                prefix={<SearchOutlined rev={undefined} />}
                value={search}
                onChange={e => onChangeSearch(e.target.value)}
              />
            </BaseFormItem>
          </BaseCol>
        </BaseRow>
      </HeaderWrapper>

      <BaseTableManagement
        columns={columns}
        dataSource={data}
        rowClassName="row-overwrite-style"
        scroll={{ x: true }}
      />
    </RootWrapper>
  )
}

const RootWrapper = styled(BaseSpace)`
  background-color: ${convertedVariables.backgroundColor};
  border-radius: ${BORDER_RADIUS};

  .ant-table-wrapper .ant-table .ant-table-header {
    border-radius: 0px !important;

    .ant-table-cell {
      border-left: 0px !important;
      border-right: 0px !important;
    }
  }

  .ant-table-thead > tr > th {
    border: 0px !important;
  }

  .row-overwrite-style .ant-table-cell {
    border-left: 0px !important;
    border-right: 0px !important;
  }

  .ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:first-child {
    border-start-start-radius: 0px;
  }

  .ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:last-child {
    border-start-end-radius: 0px !important;
  }

  .name-service-column {
    width: 500px;
  }
`

const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px !important;
`
const HeaderWrapper = styled(BaseSpace)`
  padding: ${PADDING.md};
`
