/* eslint-disable @typescript-eslint/no-explicit-any */
import { CloseOutlined, FilterOutlined } from '@ant-design/icons'
import { IFilterGetExportBill } from 'app/api/accounting/model/export-bill'

import R from 'app/assets/R'
import { SvgDownIcon } from 'app/assets/svg-assets'
import { StatusEnum } from 'app/common/enum'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseDivider } from 'app/components/common/BaseDivider'
import { BasePopover } from 'app/components/common/BasePopover'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { IDataTable } from 'app/containers/Accounting/ExportBill/type'
import { usePagination } from 'app/hook'
import { IClinic } from 'app/model/unit.model'
import { useGetLocationLegalList } from 'app/react-query/hook/accounting'
import { useGetAreaList } from 'app/react-query/hook/location'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { t } from 'i18next'
import { FONT_SIZE, FONT_WEIGHT } from 'parkway-web-common'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'

interface IProps {
  onChangeFilter?: (value: IFilterGetExportBill) => void
  filter?: IFilterGetExportBill
}

export const AdvanceFilterLayout: React.FC<IProps> = ({
  filter,
  onChangeFilter,
}) => {
  const [open, setOpen] = useState(false)
  const [filterMore, setFilterMore] = useState<
    IFilterGetExportBill | undefined
  >(filter)

  const { data: areaApi, isLoading: isLoadingArea } = useGetAreaList({
    typeid: 'clinic',
    status: StatusEnum.ACTIVE,
    pagesize: 30,
  })

  const { flattenDataList } = usePagination()

  const clinics: IClinic[] = useMemo(() => {
    return flattenDataList(areaApi) ?? []
  }, [areaApi])

  const { data: dataLocationLegal } = useGetLocationLegalList()

  const dataFlattenLocation = useMemo(() => {
    const res = flattenDataList(dataLocationLegal)
    return res
  }, [dataLocationLegal])

  const locationLegalList = useMemo(() => {
    return dataFlattenLocation?.data
      ?.filter(i => !!i?._id)
      ?.map((item, index) => {
        return {
          label: item?.name,
          value: item?._id,
          key: index + 1,
        } as IDataTable
      })
  }, [dataFlattenLocation])

  // action change
  const onChangeClinic = (clinicIds: string[]) => {
    setFilterMore?.({ ...filterMore, clinicIds })
  }
  const onChangeCompany = (clinicLegalNameIds: string[]) => {
    setFilterMore?.({ ...filterMore, clinicLegalNameIds })
  }

  const onHide = () => {
    setOpen(false)
  }

  const onPressApply = () => {
    onHide()
    onChangeFilter?.({
      ...filter,
      clinicIds: filterMore?.clinicIds,
      clinicLegalNameIds: filterMore?.clinicLegalNameIds,
    })
  }

  const onPressReset = () => {
    setFilterMore({})
    onChangeFilter?.({
      ...filter,
      clinicIds: [],
      clinicLegalNameIds: [],
    })
  }

  return (
    <BasePopoverWrapper
      trigger={'click'}
      placement="bottomLeft"
      content={
        <BaseSpace
          split={<BaseDivider style={{ marginBottom: 0, marginTop: 0 }} />}
          size={12}
        >
          <BaseRow gutter={[16, 16]} align={'middle'} justify={'space-between'}>
            <BaseCol>
              <BaseText
                children={'Lọc thêm'}
                fontSize="xs"
                fontWeight="semibold"
              />
            </BaseCol>

            <BaseCol>
              <BaseButton
                icon={<CloseOutlined rev={undefined} />}
                onClick={onHide}
                type="text"
              />
            </BaseCol>
          </BaseRow>

          <BaseForm>
            <BaseSpace style={{ width: 320 }} size={4}>
              <BaseFormItem label={t(R.strings.branch)}>
                <BaseSelectWrapper
                  mode="multiple"
                  showSearch
                  placeholder={t(R.strings.all)}
                  allowClear
                  onChange={ids => onChangeClinic?.(ids as string[])}
                  options={clinics?.map(item => ({
                    value: item._oldId,
                    label: item.name,
                  }))}
                  loading={isLoadingArea}
                  maxTagCount={0}
                  value={filterMore?.clinicIds}
                />
              </BaseFormItem>
              <BaseFormItem label={t(R.strings.company)}>
                <BaseSelectWrapper
                  mode="multiple"
                  showSearch
                  placeholder={t(R.strings.all)}
                  allowClear
                  onChange={ids => onChangeCompany?.(ids as string[])}
                  options={locationLegalList}
                  loading={isLoadingArea}
                  maxTagCount={0}
                  value={filterMore?.clinicIds}
                />
              </BaseFormItem>
            </BaseSpace>
          </BaseForm>

          <BaseRow gutter={[16, 16]} align={'middle'} justify={'space-between'}>
            <BaseCol>
              <BaseButton
                onClick={onPressReset}
                children={t(R.strings.content_reset)}
              />
            </BaseCol>

            <BaseCol>
              <BaseButton
                onClick={onPressApply}
                type="primary"
                children={t(R.strings.content_apply)}
              />
            </BaseCol>
          </BaseRow>
        </BaseSpace>
      }
      open={open}
      onOpenChange={setOpen}
    >
      <ButtonOpen
        children={
          <BaseRow align={'middle'} justify={'center'} gutter={[8, 8]}>
            <BaseCol>
              <FilterOutlined rev={undefined} />
            </BaseCol>
            <BaseCol>
              <BaseText
                children={'Lọc thêm'}
                fontWeight="medium"
                fontSize="xs"
                opacity="0.7"
              />
            </BaseCol>
            <SvgDownIcon style={open ? { transform: 'rotate(180deg)' } : {}} />
          </BaseRow>
        }
      />
    </BasePopoverWrapper>
  )
}

const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px !important;
`

const BaseSelectWrapper = styled(BaseSelect)`
  width: 100%;
  .ant-select-selection-placeholder {
    padding-left: ${props =>
      props.$prefixWidth ? `${props?.$prefixWidth}px` : 0};
  }

  .ant-select-selection-item {
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
    color: ${convertedVariables.primaryColor};
    opacity: 0.9;
  }
`

const ButtonOpen = styled(BaseButton)`
  border-color: #f0f1f3;

  &:hover {
    .anticon {
      color: ${convertedVariables.primaryColor};
      opacity: 0.7;
    }
  }
`

const BasePopoverWrapper = styled(BasePopover)``
