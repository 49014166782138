/* eslint-disable @typescript-eslint/no-explicit-any */
import { DeleteFilled, ImportOutlined, InboxOutlined } from '@ant-design/icons'
import { requestRequestImportTreatment } from 'app/api/marketing/discount-campaign'
import { DiscountCampaignMessageCode } from 'app/api/marketing/discount-campaign/constant'
import { DiscountTypeEnum } from 'app/api/marketing/discount-campaign/model'
import { DefineDiscountTypeEnum } from 'app/api/marketing/discount-voucher/constant'
import R from 'app/assets/R'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseUpload } from 'app/components/common/BaseUpload'
import { BaseButtonsForm } from 'app/components/common/forms/BaseButtonsForm'
import { IRefModal, ModalComponent } from 'app/components/common/ModalComponent'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { _DEV_ } from 'app/constant'
import { isEqual } from 'lodash'
import {
  FONT_SIZE,
  formatMoney,
  normFile,
  PADDING,
  ResponseType,
} from 'parkway-web-common'
import { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { DefineStatusEnum } from './constant'
import { IResponseImportFile, StatusEnum } from './type'
import { useCreateCampaignContext } from 'app/containers/Marketing/DiscountCampaignManagement/CloneCampaign/context'

export const ImportFileModal = () => {
  const { campaignType, onSuccessImportTreatment } = useCreateCampaignContext()
  const refModal = useRef<IRefModal>({})
  const { t } = useTranslation()
  const [fileSelected, setFileSelected] = useState<File | null | undefined>(
    null,
  )
  const [isLoading, setIsLoading] = useState(false)

  const [listTreatment, setListTreatment] = useState<IResponseImportFile[]>([])

  const _onConfirmModal = async () => {
    if (successData?.length) {
      onSuccessImportTreatment?.(successData)
    }
    refModal?.current?.hide?.()
    setFileSelected(null)
    setListTreatment([])
  }

  const onRemoveFile = () => {
    setFileSelected(null)
    setListTreatment([])
  }

  const onChangeFile = async (values: { file: any }) => {
    const { file } = values
    const { lastModified, name, type, originFileObj } = file
    const newFile = new File([originFileObj], name, { type, lastModified })
    if (!newFile) return
    if (!campaignType) return

    setFileSelected(newFile)

    try {
      setIsLoading(true)
      const formData = new FormData()

      formData.append('file', newFile)
      formData.append('campaignType', campaignType)

      const res: ResponseType<IResponseImportFile[]> =
        await requestRequestImportTreatment(formData)
      if (
        isEqual(
          res?.msgcode,
          DiscountCampaignMessageCode.DiscountCampaign.success,
        )
      ) {
        setListTreatment(res.data)
      }
    } catch (error) {
      _DEV_ && console.log('error', error)
    } finally {
      setIsLoading(false)
    }
  }

  const onOpenModal = () => {
    refModal.current?.open?.()
  }

  const dataTreatment = useMemo(() => {
    return listTreatment?.map((item, index) => {
      return {
        ...item,
        key: index + 1,
      }
    })
  }, [listTreatment])

  const successData = useMemo(() => {
    return listTreatment?.filter(item =>
      isEqual(item.status, StatusEnum.SUCCESS),
    )
  }, [listTreatment])

  const errorData = useMemo(() => {
    return listTreatment?.filter(
      item => !isEqual(item.status, StatusEnum.SUCCESS),
    )
  }, [listTreatment])

  const handleCancel = () => {
    setFileSelected(null)
    setListTreatment([])
  }

  return (
    <ModalComponent
      titleModal={R.strings.import_file}
      widthModal={1000}
      buttonOpenCustom={
        <BaseButton
          icon={<ImportOutlined rev={undefined} />}
          onClick={onOpenModal}
        >
          {t(R.strings.import)}
        </BaseButton>
      }
      ref={refModal}
      handleSubmit={_onConfirmModal}
      handleCancel={handleCancel}
      renderContent={
        <BaseSpace size={8}>
          <DraggerInput
            name="dragger"
            valuePropName="file"
            getValueFromEvent={normFile}
            noStyle
          >
            <BaseUpload.Dragger
              name="files"
              onChange={onChangeFile}
              customRequest={() => undefined}
              showUploadList={false}
              accept=".xls, .xlsx"
            >
              <DraggerWrapper>
                <IconMailBox rev={undefined} size={32} />
              </DraggerWrapper>
            </BaseUpload.Dragger>
          </DraggerInput>
          {fileSelected && (
            <FileNameDisplayWrapper
              gutter={20}
              justify={'start'}
              align={'middle'}
            >
              <BaseCol>
                <BaseText
                  fontSize="xs"
                  fontWeight="medium"
                  colorText="statesGreenColor"
                >
                  {fileSelected?.name}
                </BaseText>
              </BaseCol>
              <BaseCol>
                <BaseButton
                  icon={<DeleteFilled rev={undefined} onClick={onRemoveFile} />}
                  size="small"
                />
              </BaseCol>
            </FileNameDisplayWrapper>
          )}

          {fileSelected ? (
            <BaseSpace size={8}>
              <BaseRow
                align={'middle'}
                justify={'space-between'}
                gutter={[8, 8]}
              >
                <BaseCol>
                  <BaseText
                    children={'Danh sách treatments'}
                    fontWeight="medium"
                    fontSize="xs"
                    opacity="0.7"
                  />
                </BaseCol>
                <BaseCol>
                  <BaseRow gutter={8} align={'middle'}>
                    <BaseCol>
                      <BaseText
                        children={`${successData?.length} ${t(
                          R.strings.success,
                        )}`}
                        fontWeight="medium"
                        colorText="statesGreenColor"
                      />
                    </BaseCol>
                    <BaseCol>
                      <BaseText
                        children={`${errorData?.length} ${t(
                          R.strings.error_vn_text,
                        )}`}
                        fontWeight="medium"
                        colorText="statesRedColor"
                      />
                    </BaseCol>
                  </BaseRow>
                </BaseCol>
              </BaseRow>
              <BaseTableManagement
                pagination={{
                  pageSize: 5,
                }}
                columns={[
                  convertColumnTable<IResponseImportFile>({
                    key: 'rowNumber',
                    title: t(R.strings.row),
                    classNameWidthColumnOverwrite: 'small-column',
                    render: (_, record) => {
                      return (
                        <BaseText
                          children={record?.rowNumber}
                          fontWeight="medium"
                          opacity="0.7"
                        />
                      )
                    },
                  }),
                  convertColumnTable<IResponseImportFile>({
                    key: 'name',
                    title: t(R.strings.name),
                    render: (_, record) => {
                      return (
                        <BaseText
                          children={record?.name ?? '-'}
                          fontWeight="medium"
                        />
                      )
                    },
                  }),
                  convertColumnTable<IResponseImportFile>({
                    key: 'discount',
                    title: t(R.strings.discount_value),
                    render: (_, record) => {
                      return (
                        <BaseText
                          children={formatMoney(
                            record?.discount ?? '',
                            isEqual(record?.dType, DiscountTypeEnum.Percent)
                              ? '%'
                              : 'đ',
                            undefined,
                            1,
                          )}
                          fontWeight="medium"
                          opacity="0.7"
                        />
                      )
                    },
                  }),
                  convertColumnTable<IResponseImportFile>({
                    key: 'dType',
                    title: t(R.strings.discount_type),
                    render: (_, record) => {
                      return (
                        <BaseText
                          children={
                            record?.dType
                              ? t(
                                  DefineDiscountTypeEnum[record?.dType]
                                    ?.keyI18n,
                                )
                              : '-'
                          }
                          fontWeight="medium"
                          opacity="0.7"
                        />
                      )
                    },
                  }),
                  convertColumnTable<IResponseImportFile>({
                    key: 'status',
                    title: t(R.strings.status),
                    render: (_, record) => {
                      return (
                        <BaseText
                          children={
                            record?.status
                              ? t(DefineStatusEnum[record?.status]?.text)
                              : '-'
                          }
                          fontWeight="medium"
                          style={
                            record?.status
                              ? {
                                  color:
                                    DefineStatusEnum[record?.status]?.color,
                                }
                              : undefined
                          }
                        />
                      )
                    },
                  }),
                ]}
                dataSource={dataTreatment}
                loading={isLoading}
                scroll={{ x: true }}
              />
            </BaseSpace>
          ) : null}
        </BaseSpace>
      }
    />
  )
}

const DraggerInput = styled(BaseButtonsForm.Item)``

const DraggerWrapper = styled.div`
  padding: ${PADDING.xxl} 0px;
  & svg {
    fill: var(--gray);
  }
`

const IconMailBox = styled(InboxOutlined)`
  & svg {
    width: ${FONT_SIZE.xxxl};
    height: ${FONT_SIZE.xxxl};
  }
`

const FileNameDisplayWrapper = styled(BaseRow)`
  color: var(--primary-color);
  padding-top: ${PADDING.xxs};
  & svg {
    fill: red;
  }
`
